import Home from '../pages/Home'
import Thanks from '../pages/Thanks'
import NotFound from '../pages/elements/404'

export const routes = [
  {
    path:'/',
    name: 'Home',
    component: Home
  },
  {
    path:'/thanks',
    name: 'Thanks',
    component: Thanks
  },
  {
    path:'/:slug',
    name: 'CustomHome',
    component: Home
  },
  {
    path:'*',
    name: 'NotFound',
    component: NotFound
  },
];
