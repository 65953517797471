<template>
  <div class="clinic-work-step-wrap section-space--ptb_120" id="how-it-works">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title-wrapper text-center section-space--pb_90">
            <h6 class="section-sub-title mb-20 font-weight--bold wow move-up">How Pathminder works</h6>
            <h2 class="section-title font-weight--bold wow move-up">{{ howItWorksHeader }}</h2>
          </div>
        </div>
      </div>
      <!-- clinic Process Step first Start -->
      <div class="clinic-work-process-step ">
        <div class="row">
          <div class="col-lg-6 wow move-up">
            <div class="clinic-work-step__media images-left-ml  text-center">
              <img src="../../assets/img/illustration/checklist.png" class="img-fluid" alt="thumbnail">
            </div>
          </div>
          <div class="col-lg-5 wow move-up">
            <div class="clinic-work-process-step__content">
              <div class="app-showcase__inner">
                <div class="sub__title mb-20">
                  <h6 class="font-weight--bold">Step 1</h6>
                </div>
                <h4 class="font-weight--bold">{{ howItWorks[0].header }}</h4>
                <div class="sub-text section-space--mb_40">
                  <VueShowdown>{{ howItWorks[0].description }}</VueShowdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- clinic Process Step first End -->
      <!-- clinic Process Step second Start -->
      <div class="clinic-work-process-step section-space--pt_90">
        <div class="row">
          <div class="col-lg-6 order-lg-1 order-2 wow move-up">
            <div class="clinic-work-process-step__content">
              <div class="app-showcase__inner">
                <div class="sub__title mb-20">
                  <h6 class="font-weight--bold">Step 2</h6>
                </div>
                <h4 class="font-weight--bold">{{ howItWorks[1].header }}</h4>
                <div class="sub-text section-space--mb_40">
                  <VueShowdown>{{ howItWorks[1].description }}</VueShowdown>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 order-lg-2 order-1 wow move-up">
            <div class="clinic-work-step__media images-right-mr  text-center">
              <img src="../../assets/img/illustration/pathways.svg" class="img-fluid" alt="thumbnail">
            </div>
          </div>

        </div>
      </div>
      <!-- clinic Process Step second End -->
      <!-- clinic Process Step Three Start -->
      <div class="clinic-work-process-step  section-space--pt_90">
        <div class="row">
          <div class="col-lg-6 wow move-up">
            <div class="clinic-work-step__media images-left-ml  text-center">
              <img src="../../assets/img/illustration/debrief.png" class="img-fluid" alt="thumbnail">
            </div>
          </div>
          <div class="col-lg-6 wow move-up">
            <div class="clinic-work-process-step__content">
              <div class="app-showcase__inner">
                <div class="sub__title mb-20">
                  <h6 class="font-weight--bold">Step 3</h6>
                </div>
                <h4 class="font-weight--bold">{{ howItWorks[2].header }}</h4>
                <div class="sub-text section-space--mb_40">
                  <VueShowdown>{{ howItWorks[2].description }}</VueShowdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- clinic Process Step Three End -->
    </div>
  </div>
</template>

<script>
  export default{
    props: {
      howItWorksHeader: {
        type: String,
        default: 'How it works'
      },
      howItWorks: {
        type: Array,
        default: function() { return [
          {
            header: '',
            description: ''
          },
          {
            header: '',
            description: ''
          },
          {
            header: '',
            description: 'D3'
          },
        ]}
      },
    }
  };
</script>
