<template>
  <div class="newsletter-section section-space--ptb_120 default-bg" id="signup">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-10  ml-auto mr-auto">
          <div class="section-title-wrapper text-center section-space--mb_50 wow move-up">
            <h3 class="section-title text-white mt-40 mb-20">Thanks!</h3>
            <h5 class="text-white text-left mb-20">
              Thanks so much for your interest in Pathminder. We’ll be in touch to arrange a demo as soon as we can.
            </h5>
            <h5 class="text-white text-left mb-20">
              We’re excited to start helping practitioners
              like you gain stronger relationships and see greater real-life improvements in your clients’ outcomes.
              </h5>

              <h5 class="text-white text-left mb-20">
                You can email us at <a class="hover-style-link hover-style-link--green" href="mailto:info@pathminder.io">info@pathminder.io</a>, so feel free to drop us
                a line if you have questions, feedback, or just want to say ‘hey’. We'd love to hear from you.
              </h5>

            <h6>
              <router-link class="hover-style-link hover-style-link--green" to="/">Back to Pathminder home.</router-link>
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'newsletter'
  }
</script>
