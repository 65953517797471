import Vue from 'vue'
import Vuex from 'vuex'

const storeConfig = {
  state: {
    landingPage: {},
  },
  mutations: {
    setLandingPage(state, pages) {
      // update the default page with nonzerolength values from the specified page
      for (let key in pages.page) {
        if (pages.page[key].length == 0) {
          delete pages.page[key]
        }
      }
      state.landingPage = {
        ...pages.defaultpage,
        ...pages.page,
      }
    }
  },
  actions: {
    async getLandingPage({ commit }, version) {
      await commit(
        'setLandingPage', {
          page: require(`@/../assets/content/landing/${version}`),
          defaultpage: require(`@/../assets/content/landing/default`)
        }
      )
    }
  }
}

Vue.use(Vuex)
export default new Vuex.Store(storeConfig)
