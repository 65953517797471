<template>
  <b-navbar toggleable="xl" fixed="top" v-b-scrollspy:nav-scroller class="header-area text-white" :class="{'is-sticky': scrolled}">
    <div class="container-fluid container-fluid--cp-150">
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
      <b-navbar-brand class="navbar-brand logo-light" to="/">
        <img :src='"../assets/img/logo/logo-white.png"' alt="logo">
      </b-navbar-brand>
      <b-navbar-brand class="navbar-brand logo-purple" to="/">
        <img :src='"../assets/img/logo/logo-purple.png"' alt="logo">
      </b-navbar-brand>
      <b-collapse class="default-nav justify-content-center"  is-nav id="nav_collapse">
        <b-navbar-nav class="navbar-nav main-menu text-white">
          <b-nav-item href="#home" class="scroll"><span>&nbsp;</span></b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
  export default {
    name:'HeaderEmpty',
    props: {
      btnText: {
        type: String,
        default: 'Get your invite'
      },
    },
    data (){
      return {
        load: false,
        limitPosition: 200,
        scrolled: false,
        lastPosition: 500,
      }
    },
    methods: {
      // sticky menu script
      handleScroll() {
        if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
          this.scrolled = true;
          // move up!
        }

        if (this.lastPosition > window.scrollY) {
          this.scrolled = true;
          // move down
        }

        this.lastPosition = window.scrollY;
        this.scrolled = window.scrollY > 50;
      }
    },
    created() {
      window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
    },
  }
</script>
