<template>
  <div class="main-container">
    <!-- Navbar section -->
    <HeaderSass
      :btn-text="landingPage.btnText"
    />

    <!-- hero section -->
    <HeroSass class="hero-sass"
      :uvp="landingPage.uvp"
      :subheader="landingPage.subheader"
      :btn-text="landingPage.btnText"
    />

    <!-- three step process -->
    <HowItWorks
      :how-it-works-header="landingPage.howItWorksHeader"
      :how-it-works="landingPage.howItWorks"
    />

    <!-- sass feature component -->
    <KeyFeatures class="gray-gradient" id="key-features"
      :features-header="landingPage.featuresHeader"
      :features-subheader="landingPage.featuresSubheader"
      :features="landingPage.features"
    />

    <!-- signup section -->
    <Signup
      :signup-header="landingPage.signupHeader"
      :signup-description="landingPage.signupDescription"
      :signup-button="landingPage.signupButtonCTA"
    />

    <!-- footer section -->
    <FooterMain FooterStyle="bg_dark-footer" />

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import HeaderSass from "../components/HeaderSass";
  import HeroSass from "../components/HeroSass";
  import HowItWorks from '@/components/sections/HowItWorks'
  import KeyFeatures from "../components/KeyFeatures";
  import Signup from '@/components/Signup'
  import FooterMain from "../components/FooterMain";

  export default {
    name: "app",
    components: {
      HeaderSass,
      HeroSass,
      HowItWorks,
      KeyFeatures,
      Signup,
      FooterMain
    },
    computed: {
      ...mapState(['landingPage'])
    },
  };
</script>
