<template>
  <div class="saas-feature section-space--ptb_90 bg-gray">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title text-center section-space--mb_60">
            <h2 class="font-weight--light mb-15 wow move-up">{{ featuresHeader }}</h2>
            <p class="mb-15 wow move-up">{{ featuresSubheader }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-for="feature in features" :key="feature.id" class="col-lg-4 col-md-6 wow move-up">
          <div class="feature">
          <div class="image__media">
            <img :src="feature.icon" class="img-fluid" alt="">
          </div>
          <h6 class="heading">{{ feature.header }}</h6>
          <div class="service_text"><VueShowdown>{{ feature.description }}</VueShowdown></div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SassFeature',
    props: {
      featuresHeader: {
        type: String,
        default: 'Features'
      },
      featuresSubheader: {
        type: String,
        default: ''
      },
      features: {
        type: Array,
        default: function() {
          return []
        },
      }
    }
  }
</script>

<style lang="scss" scoped>
  .feature {
    background-color: #f0f;
    margin-bottom:  25px;
    padding: 35px 40px 49px 40px;
    box-shadow: 0 4px 20px rgba(51, 51, 51, 0.05);
    background: #ffffff;
    border-radius: 5px;
    display: block;
    position: relative;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: calc(100% - 25px);

     & .image__media {
        position: relative;
        background: #f6f5f9;
        border-radius: 50%;
        height: 60px;
        line-height: 60px;
        width: 60px;
        margin-bottom: 16px;
        text-align: center;
    }

    & h6 {
      margin-bottom: 5px;
    }
  }
</style>
