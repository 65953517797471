<template>
  <div class="hero-sass hero-swiper-btn" id="home">
    <div class="container_f1">
      <div class="swiper-wrapper">
        <swiper :options="swiperOption">
          <div class="swiper-slide" :style="bgImg">
            <div class="hero-item text-center">
              <div class="hero-content">
                <h2 class="h1 hero-content-title font-weight-light">
                  <span v-html="uvp"></span>
                </h2>
                <h6 class="hero-content-subtitle"><span v-html="subheader"></span></h6>
                <b-link class="btn btn--green scroll" href="#signup"><span class="btn-icon ml-0 mr-2"><i class="fa fa-rocket"></i></span>{{ btnText }}</b-link>
              </div>
              <div class="hero-thumb">
                <img src="../assets/img/illustration/main.svg" class="img-fluid" alt="Image of practitioner and client collaborating over an app">
              </div>
            </div>
          </div>
        </swiper>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    uvp: {
      type: String,
      default: 'Get your time back.<br/>Elevate treatment outcomes.'
    },
    subheader: {
      type: String,
      default: 'Client management, coaching and exercises.<br/>Designed by you; delivered by Pathminder'
    },
    btnText: {
      type: String,
      default: 'Get your invite'
    },
  },
  data() {
    return {
      bgImg: {
        backgroundImage: 'linear-gradient(0deg, #FFFFFF 0%, #B299F5 48%, #5E60E8 99%)',
        backgroundRepeat: 'repeat-x',
        backgroundPosition: 'center top',
      },
      swiperOption: {
        speed: 1500,
        loop: true,
        effect: 'fade',
        autoplay: false,
        spaceBetween : 30,
      }
    };
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables.scss';
  @import '../assets/scss/elements/hero-sass.scss'
</style>
