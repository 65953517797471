<template>
    <div class="footer-area-wrapper" :class="FooterStyle">
        <div class="footer-area section-space--ptb_90">
            <div class="container">
                <div class="row footer-widget-wrapper">
                        <div class="col-md-4 footer-widget__logo mb-30">
                            <router-link to="/">
                                <img src="../assets/img/logo/logo-purple.png" class="img-fluid logo-dark" alt="">
                            </router-link>
                        </div>
                    <div class="col-md-3 col-lg-2 col-8 footer-widget">
                        <ul class="footer-widget__list">
                            <li>114/201 Albert St,<br/>Brunswick,<br/>Victoria 3056,<br/>Australia</li>
                            <li><a href="mailto:info@pathminder.io" class="hover-style-link hover-style-link--green">info@pathminder.io</a></li>
                            <li><a href="https://pathminder.io" class="hover-style-link hover-style-link--green">https://pathminder.io</a></li>
                        </ul>
                    </div>
                    <div class="col-2">
                        <ul class="list ht-social-networks solid-rounded-icon">
                            <li class="item">
                                <a href="http://facebook.com/pathminder.io" target="_blank" class="social-link"> <i class="fab fa-facebook social-link-icon"></i> </a>
                            </li>
                            <li class="item">
                                <a href="https://www.linkedin.com/company/pathminder/" target="_blank" class="social-link"> <i class="fab fa-linkedin social-link-icon"></i> </a>
                            </li>
                            <li class="item">
                                <a href="https://twitter.com/pathminder_io" target="_blank" class="social-link"> <i class="fab fa-twitter social-link-icon"></i> </a>
                            </li>
                            <li class="item">
                                <a href="https://www.instagram.com/pathminder.io/" target="_blank" class="social-link"> <i class="fab fa-instagram social-link-icon"></i> </a>
                            </li>
                        </ul>
                    </div>

                    <!-- <div class="col-lg-2 col-md-6 footer-widget">
                        <h6 class="footer-widget__title mb-20">Who we are</h6>
                        <ul class="footer-widget__list">
                            <li><a href="#" class="hover-style-link">About us</a></li>
                            <li><a href="#" class="hover-style-link">Before you go</a></li>
                            <li><a href="#" class="hover-style-link">Online check in</a></li>
                            <li><a href="#" class="hover-style-link">FAQ</a></li>
                        </ul>
                    </div> -->
                    <!-- <div class="col-lg-2 col-md-6 footer-widget">
                        <h6 class="footer-widget__title mb-20">Quick links</h6>
                        <ul class="footer-widget__list">
                            <li><a href="#" class="hover-style-link">Pick up locations</a></li>
                            <li><a href="#" class="hover-style-link">Terms of Payment</a></li>
                            <li><a href="#" class="hover-style-link">Privacy Policy</a></li>
                            <li><a href="#" class="hover-style-link">Where to Find Us</a></li>
                        </ul>
                    </div> -->
                    <!-- <div class="col-lg-3 col-md-6 offset-lg-1 footer-widget">
                        <div class="twitter-feed-slider">
                            <div class="swiper-container twitter-feed-slider__container">
                                <div class="swiper-wrapper twitter-feed-slider__wrapper">
                                    <div class="swiper-slide twitter-feed-slider__single">
                                        <div class="tweet">
                                            <div class="tweet__text">
                                                "The ocean never ceases to amaze!" Feature: Ben Klea
                                                <a href="https://t.co/jSRMsZAdPW" target="_blank">https://t.co/jSRMsZAdPW</a>
                                                <a href="https://t.co/2iDReuyPMt" target="_blank">https://t.co/2iDReuyPMt</a>
                                            </div>
                                            <div class="tweet__info">
                                                <h6 class="tweet__heading">Unsplash</h6>
                                                <span class="tweet__date">May 07, 2020</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="footer-copyright-area border-top section-space--ptb_30">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-10 text-center text-md-left">
                          <span class="copyright-text">Made with <img src="../assets/img/heart.svg" alt="heart" style="height: 0.9rem; vertical-align:middle"> by <a href="https://robotsforthepeople.com"><img src="../assets/img/rftp.svg" alt="Robots for the People" style="height: 0.75rem; vertical-align: middle;"></a>&emsp;©2020&nbsp;All&nbsp;Rights&nbsp;Reserved.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FooterMain',
        props: ["FooterStyle"]
    }
</script>
