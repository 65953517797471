<template>
  <div class="newsletter-section section-space--ptb_120 default-bg" id="signup">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-10  ml-auto mr-auto">
          <div class="section-title-wrapper text-center section-space--mb_50 wow move-up">
            <h3 class="section-title text-white">{{ signupHeader }}</h3>
            <h6
              class="text-white text-center wow move-up"
            >
              <VueShowdown>{{ signupDescription}}</VueShowdown>
            </h6>
          </div>

          <!-- newsletter form -->
          <div class="newsletter-form--one section-space--mb_20 text-center wow move-up">
            <!-- If editing this, also edit the one in index.html so netlify picks it up -->
            <form
              data-netlify="true"
              name="invite"
              method="post"
              id="invite_form"
              action="/thanks"
            >
              <input type="hidden" name="form-name" value="invite" />
              <input type="email" name="email" placeholder="Your Email" required="">
              <select id="how_many_clients_do_you_regularly_see" name="how_many_clients_do_you_regularly_see" class="" required="">
                <option value="">How many clients do you regularly see?</option>
                <option value="1–9">1–9</option>
                <option value="10–24">10–24</option>
                <option value="25–49">25–49</option>
                <option value="50–99">50–99</option>
                <option value="100+">100+</option>
              </select>
              <input id="utm_source" name="utm_source" type="hidden" class="hidden">
              <input id="utm_medium" name="utm_medium" type="hidden" class="hidden">
              <input id="utm_term" name="utm_term" type="hidden" class="hidden">
              <input id="utm_campaign" name="utm_campaign" type="hidden" class="hidden">
              <input id="utm_content" name="utm_content" type="hidden" class="hidden">
              <button class="btn btn--white btn-text-theme">{{ signupButton }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'newsletter',
    props: {
      signupHeader: {
        type: String,
        default: 'Get an invite'
      },
      signupDescription: {
        type: String,
        default: 'To add your name to the invite list, fill in the form below.'
      },
      signupButton: {
        type: String,
        default: 'Get an invite!'
      },
    },
  }
</script>

<style lang="scss" scoped>
  .newsletter-section {
        border-top: 7px solid rgb(56, 203, 137);
        border-bottom: 7px solid rgb(56, 203, 137);
  }
</style>
