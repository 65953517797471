<template>
  <div class="main-container">
    <!-- Navbar section -->
    <HeaderEmpty />

    <!-- signup section -->
    <Thanks />

    <!-- footer section -->
    <FooterMain FooterStyle="bg_dark-footer" />

  </div>
</template>

<script>
  // import { mapActions, mapState } from 'vuex'
  import HeaderEmpty from "../components/HeaderEmpty";
  import Thanks from '@/components/Thanks'
  import FooterMain from "../components/FooterMain";

  export default {
    name: "app",
    components: {
      HeaderEmpty,
      Thanks,
      FooterMain
    },
  };
</script>
