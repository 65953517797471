<template>
  <div id="app">

    <router-view></router-view>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'app',
  created() {
    let slug = this.$route.params.slug || 'default';
    this.getLandingPage(slug)
  },
  methods: {
    ...mapActions(['getLandingPage']),
  },
  mounted () {
    (function() {
      scrollTo();
    })();

    function scrollTo() {
      const links = document.querySelectorAll('.scroll > a');
      links.forEach(each => (each.onclick = scrollAnchors));
      const links2 = document.querySelectorAll('a.scroll');
      links2.forEach(each => (each.onclick = scrollAnchors));
    }

    function scrollAnchors(e, respond = null) {
      const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);
      e.preventDefault();
      var targetID = (respond) ? respond.getAttribute('href') : this.getAttribute('href');
      const targetAnchor = document.querySelector(targetID);
      if (!targetAnchor) return;
      const originalTop = distanceToTop(targetAnchor);
      window.scrollBy({ top: originalTop, left: 0, behavior: 'smooth' });
      const checkIfDone = setInterval(function() {
        const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 190;
        if (distanceToTop(targetAnchor) === 0 || atBottom) {
          targetAnchor.tabIndex = '-1';
          targetAnchor.focus();
          //window.history.pushState('', '', targetID);
          clearInterval(checkIfDone);
        }
      }, 800);
    }
  },
}
</script>

